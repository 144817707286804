import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content, Process, Contact } from "page_components/secure-credit"

const Support = ({ location }) => {
  return (
    <Layout
      location={location}
      seo={{
        title: "Девелопер",
        description:
          "Численні проекти, задоволені клієнти, житлові та комерційні проекти по всій Польщі. Познайомтеся з АТ Траст Інвестмент - перевірений розробник.",
      }}
    >
      <Breadcrumbs title="Безпечний кредит 2%" />
      <PageHeader title="Довіра окупається" />
      <Content />
      <Process />
      <Contact />
    </Layout>
  )
}

export default Support
